import React from "react";
import { Layout } from "../../layout";
import Stefan from "../../images/Stefan.svg";
import { styled } from "../../../stitches.config";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Seo } from "../../components/shared/Seo";

export const UberUns = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t("uberUns.page")}
        description={t("uberUns.text1")}
        image={Stefan}
      />
      <Container>
        <Image src={Stefan} />
        <InfoBox>
          <PageTitle>{t("uberUns.title")}</PageTitle>
          <Text>{t("uberUns.text1")}</Text>
          <Text>{t("uberUns.text2")}</Text>
          <Text>
            {t("uberUns.text3")}
            <TextBold>{t("uberUns.text4")}</TextBold>
          </Text>
        </InfoBox>
      </Container>
    </Layout>
  );
};

const Container = styled("div", {
  maxWidth: 1280,
  margin: "40px auto 20px",
  padding: "0 16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "@md": {
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    margin: "80px auto 55px",
  },
});

const Image = styled("img", {
  width: "100%",
  maxWidth: 510,
  marginBottom: "20px",
  "@md": {
    margin: 0,
  },
});

const InfoBox = styled("div", {});

const PageTitle = styled("h1", {
  margin: "0 0 24px",
  fontWeight: 700,
  fontSize: "36px",
  lineHeight: "40px",
  "@md": {
    maxWidth: 720,
    fontSize: "48px",
    lineHeight: "56px",
  },
});

const Text = styled("p", {
  color: "$text",
  margin: "0 0 24px",
  fontWeight: 400,
  fontSize: "17px",
  lineHeight: "24px",
  "@md": {
    maxWidth: 620,
  },
  "&:last-child": {
    margin: "0",
  },
});

const TextBold = styled("p", {
  color: "$text",
  margin: "0 0 12px",
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "24px",
  "@md": {
    maxWidth: 620,
  },
});
