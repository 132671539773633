import { graphql } from "gatsby";
import React from "react";
import { UberUns } from "../views/UberUns/UberUns";

export default function UberUnsPage() {
  return <UberUns />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
